<template>
  <v-row>
    <template v-if="loading">
      <v-col
        v-for="n in 6"
        :key="n"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <document-card stub />
      </v-col>
    </template>
    <template v-else-if="items.length">
      <v-col
        v-for="item in items"
        :key="item.uniqueId"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <document-card
          :text="item.name"
          :url="thumbnailUrl + item.uniqueId"
          preview
          @open="openDocumentDialog(item)"
        />
      </v-col>
    </template>
    <v-col
      v-else
      cols="12"
      class="text-center"
    >
      <p>Нет отзывов.</p>
    </v-col>
    <cloud-document-dialog
      v-model="documentDialog"
      :doc-id="documentId"
      :view-url="viewUrl"
      :thumbnail-url="thumbnailUrl"
      :download-url="downloadUrl"
      :open-url="openUrl"
      :title="documentName"
      middle
    />
  </v-row>
</template>

<script>
import CloudDocumentDialog from '@/components/Document/CloudDocumentDialog'
import DocumentCard from '@/components/Document/DocumentCard'

export default {
  name: 'Reviews',
  components: { DocumentCard, CloudDocumentDialog },
  data: () => ({
    loading: false,
    items: [],

    viewUrl: '',
    thumbnailUrl: '',
    downloadUrl: '',
    openUrl: '',

    documentId: '',
    documentName: '',
    documentDialog: false
  }),
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      this.$axios.get('/api/docs/reviews').then(({ data }) => {
        this.items = data.items
        this.viewUrl = data.url.view
        this.thumbnailUrl = data.url.thumbnail
        this.downloadUrl = data.url.download
        this.openUrl = data.url.open
      }).catch(({ message }) => {
        this.items = []
        this.$toasted.error(message)
      }).finally(() => {
        this.loading = false
      })
    },
    openDocumentDialog (item) {
      this.documentId = item.uniqueId || ''
      this.documentName = item.name || ''
      this.documentDialog = true
    }
  }
}
</script>
