<template>
  <v-card
    v-if="stub"
    class="ma-1"
    elevation="3"
  >
    <v-card-title>
      <v-skeleton-loader
        type="card-heading"
        style="width: 100%;"
      />
    </v-card-title>
    <v-card-text
      v-if="preview"
      class="text-center"
    >
      <v-skeleton-loader
        type="image"
        width="150"
        height="200"
        class="mx-auto"
      />
    </v-card-text>
    <v-card-actions class="d-block text-center text-caption">
      <v-skeleton-loader type="list-item" />
    </v-card-actions>
  </v-card>
  <v-hover
    v-else
    v-slot="{ hover }"
  >
    <v-card
      class="ma-1 transition-elevation"
      style="cursor: pointer;"
      :elevation="hover ? 15 : 3"
      :class="hover ? 'mt-1' : 'mt-3'"
      @click="$emit('open')"
    >
      <v-card-title
        v-text="text"
        style="word-break: normal;"
        class="text-body-1"
      />
      <v-card-text class="text-center">
        <v-img
          v-if="preview"
          :src="url"
          width="150"
          height="200"
          class="mx-auto"
        >
          <template #placeholder>
            <v-skeleton-loader type="image" />
          </template>
        </v-img>
      </v-card-text>
      <v-card-actions class="d-block text-center text-caption text--disabled">
        Нажмите для просмотра
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'DocumentCard',
  props: {
    stub: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  }
}
</script>
